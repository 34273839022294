.overlay-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay-fix {
  position: fixed;
  width: -webkit-fill-available;
}

.overlay-wrapper {
  position: absolute;
}

.Contact-container {
  font-family: Helvetica;
  position: fixed;
  text-align: center;
  width: 100%;
  height: 100%;
  bottom: 35px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  color: white;
  text-transform: uppercase;
  font-weight: 200;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2cd);
}

.details {
  padding: 0.5rem;
}

@media (max-width: 80em) {
  .overlay-fix {
    margin: 0 1.2rem;
    width: -webkit-fill-available;
  }
  .Contact-container {
    font-size: 1rem;
  }
}
@media (min-width: 80em) {
  .Contact-container {
    font-size: 1rem;
  }
}
