@import '_arc1.scss';
@import '_aboutUs.scss';
@import '_contact.scss';
@import '_about.scss';
@import '_archives.scss';
@import '_project.scss';
@import '_nav.scss';

html,
body,
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  list-style-type: none;
  margin: 1em 0;
}

@font-face {
  font-family: FogtwoNo5;
  src: url(fonts/FogtwoNo5.ttf);
}

@font-face {
  font-family: Atkinson-Hyperlegible-Regular-102;
  src: url(fonts/Atkinson-Hyperlegible-Regular-102.ttf);
}

@font-face {
  font-family: bodoni;
  src: url(fonts/bodoni.ttf);
}

@font-face {
  font-family: Helvetica;
  src: url(fonts/Helvetica.ttf);
}

@font-face {
  font-family: Helvetica-Bold;
  src: url(fonts/Helvetica-Bold.ttf);
}

.main {
  scroll-snap-type: y mandatory;
  scroll-snap-stop: always;
  overflow-y: scroll;
  height: 100vh;
  font-family: Helvetica;
}

a {
  text-decoration: none;
  color: black;
}
//Home scsss

.homeScreen-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  scroll-snap-align: start;
  width: 100%;
}

@media (max-width: 80em) {
  .rightSidebar-container {
    height: 20%;
  }
}

@media (min-width: 80em) {
  .homeScreen-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  .rightSidebar-container {
    // background-color: aqua;
    // margin-left: 80%;
    padding-bottom: 20px;
    padding-right: 10px;
    max-width: 20%;
    // display: flex;
    // flex-direction: column;
    align-items: flex-end !important;
    justify-content: flex-end !important;

    h4,
    p {
      padding-right: 15%;
    }

    h4 {
      margin: 0.2rem 0;
      font-size: 20px;
    }

    .footer {
      text-transform: uppercase;
    }
  }

  .centre-container {
    display: flex;
    max-width: 60%;
    min-height: 100%;
  }
}

.centre-container {
  display: flex;
  width: 100%;
  height: 60%;
}

.banner {
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.rightSidebar-container {
  // position: fixed;
  // margin-top: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 15px;

  h4 {
    margin: 0.2rem 0;
  }

  .footer {
    text-transform: uppercase;
  }
}

// archives page

.archives {
  justify-content: center;

  h2 {
    margin-left: 30px;
  }
}

.archives-container {
  margin-top: 2%;
  width: 100%;
  justify-content: space-around;
  display: grid;
  grid-template-columns: repeat(auto-fit, 14rem);
}

.archive {
  width: 100%;
  position: relative;

  img {
    border-radius: 5px;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  p {
    position: absolute;
    top: 8px;
    writing-mode: vertical-rl;
    font-size: 35px;
    letter-spacing: -0.15rem;
    word-spacing: 7px;
  }
}

//CONTACT US

// ARC1 SOCKS

// MIDNIGHT

.midnight {
  background-color: white;
}

.arcSock-title {
  margin-top: 80px;
  position: fixed;
}

.sock-container {
  position: absolute;
  width: 800px;
  height: 800px;
}

.s1 {
  right: 66%;
  bottom: -35%;
}

.s2 {
  right: 42%;
  bottom: 9%;
}

.s3 {
  right: 18%;
  bottom: 53%;
}

.s4 {
  right: 23%;
  bottom: -35%;
}
