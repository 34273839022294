.video-container {
  justify-content: center;
  flex-direction: column;
}

// video {
//   width: 100%;
//   height: 70%;
//   object-fit: cover;
// }

.video-wrapper {
  width: 100%;
  // max-width: 700px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding-bottom: 2rem;
}

.video-wrapper:hover .controls {
  transform: translateY(0%);
}

.aboutUs-container {
  width: 100%;
}

button {
  background: none;
  border: none;
  color: black;
  padding: 0;

  font-size: 1.1rem;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  bottom: 30px;
  padding: 14px;
  width: 100%;
  max-width: 500px;
  flex-wrap: wrap;
  transform: translateY(150%);
  transition: all 0.3s ease-in-out;
}

.mute-btn {
  position: absolute;
  bottom: 0%;
  right: 5%;
  height: 30px;
  width: 30px;
}

.togglePlay-container {
  height: 30px;
  width: 30px;
  bottom: 0%;
  position: absolute;
}

@media (max-width: 79em) {
  .video-container {
    justify-content: flex-start;
  }

  .togglePlay-container {
    bottom: 0%;
    right: 12%;
  }

  .aboutUs-container {
    display: none;
  }

  .video-player {
    width: 90%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
}

@media (min-width: 79em) {
  .video-player {
    width: 90%;
    height: 100%;
    object-fit: contain;
  }

  .togglePlay-container {
    bottom: 0%;
    right: 8%;
  }
}
