//arc1 css

h2 {
  font-size: 60px;
  font-weight: 100;
  letter-spacing: -0.3rem;
  font-family: FogtwoNo5;
}

p,
h3,
h2 {
  margin: 0;
}

.title-container {
  display: none;
}

.description-container {
  display: none;
}

.socks-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, 24%);
  max-width: 100%;
  justify-content: center;
  // align-items: center;
}

.sock {
  // width: 100%;
  // height: 100%;
  margin: 2px;
  display: flex;
  align-items: center;

  img {
    object-fit: cover;
    width: 100%;
    height: 70%;
  }
}

@media (min-width: 80em) {
  .arc1 {
    justify-content: center;
  }

  .title-container {
    margin: 0 0 1rem 1rem;
    text-transform: uppercase;
    // margin-top: 17%;

    p {
      font-size: 25px;
    }
    display: flex;
    flex-direction: column;
  }

  .socks-container {
    margin: 5% 0;
    grid-template-columns: repeat(auto-fit, 25%);
  }

  .sock {
    img {
      height: 100%;
    }
  }

  .description-container {
    margin: 1rem 0 0 0;
    font-family: Atkinson-Hyperlegible-Regular-102;
    display: flex;
    text-align: center;
  }
}
