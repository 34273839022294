.Archive-container {
  justify-content: center;

  // margin-bottom: 1rem;
}

.table-container {
  text-transform: uppercase;
  // border-collapse: collapse;
  text-align: left;
  display: flex;
  ul {
    padding: 0;
  }
}

.Title {
  font-family: Helvetica-Bold;
  font-size: 3rem;
}

.Row-Container {
  //   display: flex;
  //   justify-content: space-between;
  min-width: 80%;
}
@media (min-width: 80em) {
  .Archive-container {
    display: flex;
    justify-content: center; // align-items: center;
  }
  .table-container {
    display: flex;
    // flex-direction: row;
    align-items: center;
    ul {
      padding: 0 50px;
    }
  }
}

@media (max-width: 80em) {
  .Archive-container {
    // display: flex;
    flex-direction: column;
    align-items: center;

    // margin-bottom: 1rem;
  }

  .table-container {
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;

    // justify-content: center;
    // display: block;
  }

  .Title {
    font-size: 2rem;
  }

  // tr {
  //   display: inline-flex;
  //   flex-direction: column;
  // }
}
