.sockText-container {
  margin: 0 auto;
  padding-top: 1rem;
  width: 90%;

  .sock-title {
    font-size: 1.23rem;
    text-transform: uppercase;
    font-family: Helvetica-Bold;
    padding-bottom: 2rem;
  }
}

.footer-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

@media (max-width: 80em) {
  .sock-description {
    font-size: 1rem;
  }
}
