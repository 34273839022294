.navBar-container {
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 20%;

  //   position: fixed;

  .logo-container {
    height: 40%;
    display: flex;
    justify-content: center;
    flex: 1;
    img {
      object-fit: contain;
    }
  }

  // Hover animation
  .navContents-container {
    display: flex;
    font-size: 2rem;
    width: 100%;
    flex: 1;

    // padding: 0 3rem;

    a {
      margin: 7px 0;
      font-size: 1.23rem;
      letter-spacing: -0.01rem;
      text-transform: uppercase;
      display: inline-block;
      position: relative;
    }

    a::after {
      content: '';
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: black;
      transform-origin: bottom right;
      transition: transform 0.4s ease-out;
    }

    a:hover::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
}

@media (max-width: 80em) {
  .non-fixed {
    margin: 0 auto;
    width: 90%;
  }
  .navBar-container {
    .navContents-container {
      justify-content: space-between;
    }
    .logo-container {
      display: flex;
      justify-content: flex-start;
      img {
        width: 2.5rem;
      }
    }
  }
}

@media (min-width: 80em) {
  .navBar-container {
    width: 20%;
    min-height: 100vh;
    flex-direction: column;

    .navContents-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex: 1;
      align-items: center;
      justify-content: center;
    }

    .logo-container {
      width: 100%;
      // max-height: 10%;
      display: flex;
      align-items: flex-start;
      flex: 1;
      justify-content: center;
      // margin-left: 8px;

      img {
        padding-top: 3rem;
        object-fit: contain;
        width: 25%;
        height: 30%;
      }
    }

    .footer-container {
      flex: 1;
    }
  }
}
